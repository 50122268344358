import { useEffect, useState, useRef } from 'react';
import './App.css';
import useFetch from "./hooks/useFetch";
import { useDispatch, useSelector } from 'react-redux';
import { setAllTrips, setPrices, setTripImages } from './store/features/trip/tripSlice';
import { Route, Routes, useLocation } from 'react-router-dom';
import Home from './screens/home/Home';
import Signin from './components/signin/Signin';
import Register from './components/register/Register';
import Booking from './screens/booking/Booking';
import Preloader from './components/preloader/Preloader';
import Checkout from './components/checkout/Checkout';
import { setMyBookings } from './store/features/user/userSlice';
import MyBooking from './screens/myBooking/MyBooking';
import BookingDetail from './screens/bookingDetail/BookingDetail';
import ForgotPassword from './components/forgotPassword/ForgotPassword';
import ResetPassword from './components/resetPassword/ResetPassword';
import VerifyEmail from './components/verifyEmail/VerifyEmail';
import { setAgency, setAgencyLogo } from './store/features/agency/agencySlice';
import axios from 'axios';
import Share from './components/share/Share';

function App() {
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const aid = searchParams.get('aid');
  const { data: allTrips } = useFetch(`/getalltrips.php/?aid=1`);
  const { data: tripImages } = useFetch("/tripimages.php");
  const { data: prices } = useFetch("/getprices.php");
  const { data: bookings } = useFetch(`/getallbooking.php/?aid=1`);
  const [loading, setLoading] = useState(true);
  const dispatch = useDispatch();
  const prevLocationRef = useRef();
  const { logedInUser } = useSelector(state => state.user);

  useEffect(() => {

    // setting agency id to the redux store 

    dispatch(setAgency({ aid: "1" }));

    (async () => {
      if (true) {
        try {
          const resp = await axios.post(`${process.env.REACT_APP_BASE_URL}/getagencybyid.php`, { aid: "1" });
          if (resp.status === 200) {
            dispatch(setAgencyLogo(process.env.REACT_APP_BASE_URL + "/logo/" + resp.data.data[0].logo));
          }
        } catch (error) {
          console.log(error);
        }
      }
    })()

    if (allTrips && tripImages && prices) {
      const currentDate = new Date();
      const filteredTrips = allTrips
        .filter(trip => new Date(trip.startdate) >= currentDate)
        .sort((a, b) => new Date(a.startdate) - new Date(b.startdate));
      dispatch(setAllTrips(filteredTrips.filter((item) => +item.isdeleted === 0 && +item.isactive === 1)));
      dispatch(setTripImages(tripImages));
      dispatch(setPrices(prices));
      setLoading(false);
    }

    if (bookings && bookings.length > 0 && logedInUser) {
      dispatch(setMyBookings(bookings.filter((booking) => +booking.customerid === logedInUser.id)));
    }

  }, [allTrips, tripImages, prices, dispatch, logedInUser, bookings]);


  useEffect(() => {
    if (prevLocationRef.current !== location.pathname) {
      window.scrollTo(0, 0);
      prevLocationRef.current = location.pathname;
    }
  }, [location.pathname]);

  if (loading) {
    return <Preloader />
  }

  return (
    <Routes>
      <Route path="/" element={<Home />} />
      <Route path="/signin" element={<Signin />} />
      <Route path="/register" element={<Register />} />
      <Route path="/verifyemail" element={<VerifyEmail />} />
      <Route path="/forgotpassword" element={<ForgotPassword />} />
      <Route path="/resetpassword" element={<ResetPassword />} />
      <Route path="/booking/:id" element={<Booking />} />
      <Route path="/checkout/:id" element={<Checkout />} />
      <Route path="/mybooking" element={<MyBooking />} />
      <Route path="/share/:id" element={<Share />} />
      <Route path='/bookingdetail/:id' element={<BookingDetail />} />
    </Routes>
  );
}

export default App;
