import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    allTrips: [],
    tripImages: [],
    prices: [],
}

const tripSlice = createSlice({
    name: "trip",
    initialState,
    reducers: {
        setAllTrips: (state, action) => {
            state.allTrips = action.payload;
        },
        setTripImages: (state, action) => {
            state.tripImages = action.payload;
        },
        setPrices: (state, action) => {
            state.prices = action.payload;
        },
    }
});

export const { setAllTrips, setPrices, setTripImages } = tripSlice.actions;
export default tripSlice.reducer;