export const formatDate = (dateString) => {
    const options = { year: 'numeric', month: 'short', day: 'numeric' };
    return new Date(dateString).toLocaleDateString('en-US', options);
};

export const formatTime = (timeString) => {
    const [hours, minutes] = timeString.split(':');
    let formattedHours = parseInt(hours, 10) % 12;
    formattedHours = formattedHours === 0 ? 12 : formattedHours;
    const period = parseInt(hours, 10) < 12 ? 'AM' : 'PM';
    return `${formattedHours}:${minutes} ${period}`;
}